<template>
  <v-dialog max-width="900" scrollable v-model="dialog">
    <v-card elevation="2">
      <v-toolbar color="indigo darken-2" dark dense fixed>
        <v-icon>
          mdi-adobe-acrobat
        </v-icon>
        <v-toolbar-title>พิมพ์เอกสาร</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn text @click="cancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-sheet height="700" width="100%">
        <pdf-app ref="pdf" :pdf="pdfSrc" :config="config" v-if="pdfSrc != null"></pdf-app>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>

<script>
import PdfApp from 'vue-pdf-app'
export default {
  components: {
    PdfApp,
  },
  methods: {
    convertDataURIToBinary(base64) {
      var binary_string = window.atob(base64)
      var len = binary_string.length
      var bytes = new Uint8Array(len)
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i)
      }
      return bytes.buffer
    },
    open(pdfBase64) {
      this.pdfSrc = this.convertDataURIToBinary(pdfBase64)
      this.dialog = true
    },
    cancel() {
      this.pdfSrc = null
      this.dialog = false
    },
  },
  data() {
    return {
      dialog: false,
      pdfSrc: null,
      totalPages: 0,
      currentPage: 1,
      config: {
        sidebar: {
          viewThumbnail: true,
          viewOutline: true,
          viewAttachments: true,
        },
        findbar: true,
        secondaryToolbar: {
          secondaryPresentationMode: true,
          secondaryOpenFile: true,
          secondaryPrint: true,
          secondaryDownload: true,
          secondaryViewBookmark: true,
          firstPage: true,
          lastPage: true,
          pageRotateCw: true,
          pageRotateCcw: true,
          cursorSelectTool: true,
          cursorHandTool: true,
          scrollVertical: true,
          scrollHorizontal: true,
          scrollWrapped: true,
          spreadNone: true,
          spreadOdd: true,
          spreadEven: true,
          documentProperties: true,
        },
        toolbar: {
          toolbarViewerLeft: {
            print: true,
            previous: true,
            next: true,
            pageNumber: true,
          },
          toolbarViewerRight: {
            presentationMode: false,
            openFile: false,
            print: true,
            download: true,
            viewBookmark: false,
            secondaryToolbarToggle: false,
          },
          toolbarViewerMiddle: {
            zoomOut: true,
            zoomIn: true,
            scaleSelectContatiner: true,
          },
        },
        viewerContextMenu: true,
        errorWrapper: true,
      },
    }
  },
  watch: {},
}
</script>

<style>
.pdfclass {
  height: 90%;
  width: 900px;
}
</style>
